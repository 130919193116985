import React from "react"
import { graphql } from "gatsby"
import { Helmet } from "react-helmet"
import styled from "styled-components"
import { Layout } from "src/components/layout"
import { BookshelfList } from "src/components/bookshelf_list"

const BookshelfContainer = styled.p `
  display: block;
  width: 100%;
  flex-wrap: wrap;
  flex-direction: row;
  padding: 0;

  @media (min-width: 1024px) {
    width: 640px;
    margin: 2.5rem 0;
  }
`

const BookshelfTitle = styled.h1 `
  margin-top: 0rem;
  margin-bottom: 0;
  color: #000;
  font-size: 2.5rem;
  line-height: 1.25;
  font-weight: 300;
`

export default ({ data }) => {
  return (
    <Layout>
      <Helmet>
        <title>Bookshelf | {data.site.siteMetadata.title}</title>
      </Helmet>
      <BookshelfContainer>
        <BookshelfTitle>Bookshelf</BookshelfTitle>
        <BookshelfList />
      </BookshelfContainer>
    </Layout>
  )
}

export const query = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`