import React from "react"
import styled from 'styled-components'
import { useStaticQuery, graphql } from "gatsby"
import { format } from 'date-fns'


const PreviousReadsSection = styled.div `
  margin-top: 2rem;
`

const BookshelfSectionLabel = styled.div `
  font-size: 1.5rem;
  font-weight: 300;
  text-transform: lowercase;
`

const Book = styled.div `
  margin-top: 2rem;
  display: flex;
  flex-direction: row;
`

const BookCover = styled.div `
  display: block;
  /* flex-basis: 80px; */
  flex: 0 0 auto;
  height: 180px;
  overflow: hidden;
  position: relative;

  &:after {
    border: 1px solid rgba(0, 0, 0, 0.1);
    content: '';
  
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
`

const BookCoverImg = styled.img `
  margin: 0;
  width: 120px;
  /* height: 116px; */
`

const BookDetails = styled.div `
  display: flex;
  flex-direction: column;
  margin-left: 1rem;
  color: #2F4659;
`

const CurrentlyReadingLabel = styled.div `
  display: flex;
  margin-top: 0.5rem;
  flex-direction: column;
  color: #C02907;
`

const ReadDateLabel = styled.div `
  display: flex;
  margin-top: 0.5rem;
  flex-direction: column;
  color: #999;
`

const BookTitle = styled.span `
  font-size: 1.25rem;
  font-weight: 700;
  line-height: 1.4;
  
`

const BookAuthor = styled.span `
  margin-top: 0.5rem;
  font-size: 1rem;
`

const BookshelfList = () => {
  const { allBookshelfYaml } = useStaticQuery(
    graphql`
      query {
        allBookshelfYaml(limit: 50 ) {
          edges {
            node {
              title
              author
              isbn
              type
              status
              completed_on
              image {
                childImageSharp {
                  fixed(width: 123) {
                    width
                    height
                    src
                    srcSet
                  }
                }
              }
            }
          }
        }
      }
    `
  )

  return (
    <PreviousReadsSection>
      {allBookshelfYaml.edges.map(({ node }) => (  
        <Book>
          <BookCover>
            <BookCoverImg src={node.image.childImageSharp.fixed.src}/>
          </BookCover>
          <BookDetails>
            <BookTitle>
              {node.title}
            </BookTitle>
            <BookAuthor>
              {node.author}
            </BookAuthor>
            {node.status === "reading" ?
              <CurrentlyReadingLabel>
                Now reading
                </CurrentlyReadingLabel>
              :
              <ReadDateLabel>
                {format(new Date(node.completed_on), "MMMM yyyy")}
                </ReadDateLabel>
            }
          </BookDetails>
        </Book>
      ))}
    </PreviousReadsSection>
  )
}


export { BookshelfList }